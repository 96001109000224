// NOTE migrate from Flutter configs.dart

// export const IOS_STORE_APP_ID = 1539375758;

const env_configs = require("./env_configs");

// 这个"process.env"的用法见 https://stackoverflow.com/questions/30030031 ，注意必须是literal之类
const YPLUSPLUS_ACTIVE_ENV = checkNotNull(process.env.YPLUSPLUS_ACTIVE_ENV, 'YPLUSPLUS_ACTIVE_ENV')

const ENV = checkNotNull(env_configs.ENV_MAP[YPLUSPLUS_ACTIVE_ENV]({machineIp: 'undefined-machine-ip'}), 'ENV_MAP');

function checkNotNull(obj, msg = 'checkNotNull fail') {
    if (obj == null) throw new Error(msg);
    return obj;
}

module.exports = {ENV, YPLUSPLUS_ACTIVE_ENV}