import {PageProps} from "gatsby";
import * as React from "react";
import {RouteComponentProps, Router} from "@reach/router"
import {Layout} from "../../components/layout";
import {Beian} from "../../components/beian";
import {StaticImage} from "gatsby-plugin-image";
import {useDownloadAppInfo} from "../../utils/app_downloader";

/// NOTE 这么处理，为了支持`/ln/discussion?discussionId=xxx`之类的跳转链接
// ref https://github.com/gatsbyjs/gatsby/blob/master/examples/client-only-paths/src/pages/%5B...%5D.js
// ref https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/
export default (props: PageProps) => {
    return <Router basepath="/ln">
        <PrimarySubPage path="/:sub_path"/>
    </Router>
}

const PrimarySubPage = (props: RouteComponentProps) => {
    let downloadAppInfo = useDownloadAppInfo()

    return (
        <Layout>
            <div className="flex flex-col items-center justify-between p-0 m-0 h-screen bg-white">
                <div className="flex flex-col items-center justify-center p-0 m-0 flex-1 w-full">
                    <div className="flex flex-col items-center">
                        <StaticImage className="w-16 h-16" alt="icon" src="../images/icon.png"/>
                        <div className="text-md mt-1">why数理</div>

                        {downloadAppInfo.url && <span className="text-sm mt-8">
                            <a className="text-blue-600 underline" href={downloadAppInfo.url}>点此</a>
                            下载安装包
                        </span>}
                        <div className="text-sm mt-1 px-8">{downloadAppInfo.extraHint}</div>

                        <div className="text-sm mt-12">
                            <a href="/" className="text-blue-600 leading-4 underline">个人博客</a>
                        </div>
                    </div>
                </div>

                <Beian/>
            </div>
        </Layout>
    )
}

// const MyAppSection = (props: {}) => {
//     let downloadAppInfo = useDownloadAppInfo()
//
//     return <div>
//         <div className="flex flex-row items-center justify-start">
//             <Dot/>
//             <div className="mr-2"/>
//
//             <div className="border rounded px-1 flex flex-row items-center justify-start py-0.5">
//                 <StaticImage className="w-5 h-5" alt="icon" src="../images/icon.png"/>
//                 <div className="text-xs">why数理</div>
//             </div>
//
//             <div className="w-4"/>
//
//             <MyButton
//                 className="clipboard-when-tap"
//                 href={downloadAppInfo.url}
//                 text="点击下载"
//             />
//         </div>
//         <div className="h-2"/>
//         <div className="text-sm">{downloadAppInfo.extraHint}</div>
//     </div>
// }
