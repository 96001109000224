import * as React from "react";

// @ts-ignore
import {ENV_NAME_MAJOR_PROD_IN, ENV_NAME_MAJOR_PROD_OP} from "../../../config/env_configs";
// @ts-ignore
import {YPLUSPLUS_ACTIVE_ENV} from "../../../config/global_configs";

function getBeianPostfix() {
    switch (YPLUSPLUS_ACTIVE_ENV) {
        case ENV_NAME_MAJOR_PROD_OP:
            return '-2';
        case ENV_NAME_MAJOR_PROD_IN:
        default:
            return '-1';
    }
}

export class Beian extends React.Component<{}, {
    postfix: string;
}> {
    constructor(props: {}) {
        super(props);
        this.state = {
            postfix: getBeianPostfix(),
        };
    }

    render() {
        return (
            <a id="beian" href="https://beian.miit.gov.cn/"
               className="text-xs no-underline scale-75 text-gray-400">
                闽ICP备2020018592号{this.state.postfix}
            </a>
        );
    }
}